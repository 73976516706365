import {
  AnalyticsEvent,
  LogEvent,
  TelemetryEvent,
  isAnalyticsEvent,
  isAnalyticsV2Event,
  isLogEvent,
} from '@ms/yammer-telemetry';

import { applyMiddleware } from '../api/middleware';

import { reportAnalyticsEvent, reportAnalyticsEventNow } from './reportAnalytics';
import { reportAnalyticsV2Event } from './reportAnalyticsV2';
import { reportLogEvent, reportLogEventNow } from './reportLog';

export type ReportTelemetryEvent = (event: TelemetryEvent) => Promise<void>;
export const reportTelemetryEvent: ReportTelemetryEvent = async (event) => {
  const processedEvent = await applyMiddleware(event);

  if (isAnalyticsEvent(processedEvent)) {
    reportAnalyticsEvent(processedEvent);
  } else if (isAnalyticsV2Event(processedEvent)) {
    reportAnalyticsV2Event(processedEvent);
  } else {
    reportLogEvent(processedEvent);
  }
};

export type ReportTelemetryEventNow = (event: AnalyticsEvent | LogEvent) => Promise<void>;
export const reportTelemetryEventNow: ReportTelemetryEventNow = async (event) => {
  const processedEvent = await applyMiddleware(event);

  if (isAnalyticsEvent(processedEvent)) {
    await reportAnalyticsEventNow(processedEvent);
  } else if (isLogEvent(processedEvent)) {
    await reportLogEventNow(processedEvent);
  }
};
