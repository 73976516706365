import { Authenticator } from '@ms/yammer-web-authenticators';

import { reportError } from '../telemetry';

let authenticator: Authenticator | undefined;

type GetAuthenticator = () => Authenticator;
export const getAuthenticator: GetAuthenticator = () => {
  if (authenticator == null) {
    throw new Error('Authenticator has not been set!');
  }

  return authenticator;
};

type SetAuthenticator = (newAuthenticator: Authenticator) => void;
export const setAuthenticator: SetAuthenticator = (newAuthenticator) => {
  authenticator = newAuthenticator;
};

export interface AcquireTokenOptions {
  readonly scopes: string[];
  readonly claims?: string;
  readonly tenantId?: string;
  readonly silent?: boolean;
}
export type AcquireToken = (options: AcquireTokenOptions) => Promise<string>;
export const acquireTokenWithoutTelemetry: AcquireToken = async ({ scopes, claims, tenantId, silent }) =>
  getAuthenticator().acquireToken(scopes, claims, tenantId, silent);

export const acquireToken: AcquireToken = async ({ scopes, claims, tenantId, silent }) => {
  try {
    return await acquireTokenWithoutTelemetry({ scopes, claims, tenantId, silent });
  } catch (error) {
    const { type: authenticatorType } = getAuthenticator();

    reportError({
      error,
      eventProperties: {
        authenticatorType,
        errorCode: 'AuthAcquireTokenFailed',
        scopes: scopes.join(','),
      },
    });

    throw error;
  }
};

type AcquireTokenRedirect = (scopes: string[], claims?: string) => void;
export const acquireTokenRedirect: AcquireTokenRedirect = (scopes, claims) => {
  getAuthenticator().acquireTokenRedirect(scopes, claims);
};

type Logout = () => void;
export const logout: Logout = () => {
  const authInstance = getAuthenticator();

  if (authInstance.logout) {
    authInstance.logout();
  }
};
