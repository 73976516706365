import { error as logErrorToConsole } from '@ms/yammer-console-logging';
import { AnalyticsEvent, AnalyticsEventName, BaseTelemetryEvent } from '@ms/yammer-telemetry';
import { enqueueTelemetryEvents, getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { getReportAnalyticsEventsInput } from './reportAnalyticsEventsInput';
import { getThrottledReportAnalyticsEventsInBatch } from './reportInBatches';
import { reportAnalyticsEventsNow } from './reportTelemetryEventsToServer';

const eventsAllowedForReportNow: AnalyticsEventName[] = [
  'ecs_experiment_treatment',
  'external_link_clicked',
  'feedback_form_opened',
  'feedback_form_submitted',
  'group_over_flow_menu_item_clicked',
  'qanda_app_added',
  'create_teams_meeting_requested',
  'teams_qa_not_setup_page_opened',
];

type EnqueueAndThrottleReportAnalyticsEvents = <T extends BaseTelemetryEvent>(events: T[]) => void;
export const enqueueAndThrottleReportAnalyticsEvents: EnqueueAndThrottleReportAnalyticsEvents = <
  T extends BaseTelemetryEvent
>(
  events: T[]
) => {
  enqueueTelemetryEvents<T>('Analytics', events);
  const throttledReportAction = getThrottledReportAnalyticsEventsInBatch(
    getTelemetryClientConfig().eventReportIntervalInMilliseconds
  );

  throttledReportAction();
};

type ReportAnalyticsEvent = (event: AnalyticsEvent) => void;
export const reportAnalyticsEvent: ReportAnalyticsEvent = (event) => {
  enqueueAndThrottleReportAnalyticsEvents([event]);
};

type ReportAnalyticsEventNow = (event: AnalyticsEvent) => Promise<void>;

export const reportAnalyticsEventNow: ReportAnalyticsEventNow = async (event) => {
  if (!eventsAllowedForReportNow.includes(event.name as AnalyticsEventName)) {
    logErrorToConsole('Event not allowed for report now');

    return;
  }

  const analyticsEventInputs = await getReportAnalyticsEventsInput([event]);
  await reportAnalyticsEventsNow({
    events: analyticsEventInputs,
  });
};
