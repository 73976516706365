import { checkAuthCookieAvailability, clearAuthCookie, writeAuthCookie } from './authCookie';
import { clearRingInfoCookie, writeRingInfoCookie } from './ringInfoCookie';

export interface WriteHostingCookiesOptions {
  readonly yammerToken: string;
  readonly shouldSetSameSite?: boolean;
  readonly shouldSetAuthCookieExpiry?: boolean;
  readonly shouldSetRingInfoCookie?: boolean;
  readonly ringInfoCookiePath?: string;
  readonly partitioned?: boolean;
}

type WriteHostingCookies = (options: WriteHostingCookiesOptions) => void;
export const writeHostingCookies: WriteHostingCookies = ({
  yammerToken,
  shouldSetSameSite = false,
  shouldSetAuthCookieExpiry = false,
  shouldSetRingInfoCookie = false,
  partitioned = false,
  ringInfoCookiePath,
}) => {
  if (process.env.YAMMER_ENV === 'development') {
    return;
  }

  writeAuthCookie({ yammerToken, shouldSetSameSite, shouldSetExpiry: shouldSetAuthCookieExpiry, partitioned });

  if (shouldSetRingInfoCookie && !!ringInfoCookiePath) {
    writeRingInfoCookie(shouldSetSameSite, ringInfoCookiePath, partitioned);
  }
};

export interface ClearHostingCookiesOptions {
  readonly shouldRemoveRingInfoCookie?: boolean;
  readonly ringInfoCookiePath?: string;
}

type ClearHostingCookies = (options?: ClearHostingCookiesOptions) => void;
export const clearHostingCookies: ClearHostingCookies = (options) => {
  clearAuthCookie();

  if (options?.shouldRemoveRingInfoCookie && options?.ringInfoCookiePath) {
    clearRingInfoCookie(options?.ringInfoCookiePath);
  }
};

type IsAuthCookieAvailable = () => boolean;
export const isAuthCookieAvailable: IsAuthCookieAvailable = () => checkAuthCookieAvailability();
