type GetUrlWithoutQueryParams = (sourceUrl: string) => string;
export const getUrlWithoutQueryParams: GetUrlWithoutQueryParams = (sourceUrl) => {
  if (!sourceUrl) {
    return '';
  }

  const url = new URL(sourceUrl);
  url.search = '';

  return url.toString();
};

/**
 * `invoker` property for event-listener invokers with source URL
 * will always have the format `<tagName>[src=url].<event-handler-property>`.
 * Example: `IMG[src=https://example.com/img.jpg].onload`
 * Doc: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceScriptTiming/invoker
 */
const sourceUrlRegex = new RegExp(/\[src=(http.*)\]/);

type GetSanitizedInvoker = (invoker: string) => string;
export const getSanitizedInvoker: GetSanitizedInvoker = (invoker) => {
  if (invoker.startsWith('http')) {
    return getUrlWithoutQueryParams(invoker);
  }

  const matches = sourceUrlRegex.exec(invoker);
  if (matches && matches.length > 1) {
    const url = getUrlWithoutQueryParams(matches[1]);
    const sanitizedInvoker = invoker.replace(matches[1], url);

    return sanitizedInvoker;
  }

  return invoker;
};
