import {
  EndAndReportPerformanceMeasure,
  PerformanceEvent,
  PerformanceEventName,
  PerformanceMeasure,
  StartPerformanceMeasure,
} from '@ms/yammer-telemetry';
import { NavigationPerformanceEventProperties, PerformanceEventProperties } from '@ms/yammer-telemetry-support';
import { v4 as uuidv4 } from 'uuid';
import { Metric, getCLS, getFID, getLCP, getTTFB } from 'web-vitals';

import { reportTelemetryEvent } from '../report';
import { enqueueAndThrottleReportLogEvents } from '../report/reportLog';

import { filterUnsafeProperties } from './eventPropertyCheck';

/**
 * Ends a performance event and reports it to telemetry. Underneath the hood, this adds both an end event
 * and a measure event (which measures the duration between start and end events) to the browser's performance events.
 * You can verify these marks locally by calling window.performance.getEntries() with your browser devtools.
 * The duration (ms), startTime, and endTime (both ms since app booted) will be logged in columns in the 'yamjs' table
 * @param options ReportPerformanceOptions
 */
export const endAndReportPerformanceMeasure: EndAndReportPerformanceMeasure = (options) => {
  if (!isBrowserPerformanceApiAvailable()) {
    return;
  }

  const { measureKey, eventName, eventProperties = {}, fromNavigationStart, durationOffset = 0 } = options;

  endMeasure(measureKey, fromNavigationStart);

  const { duration, startTime } = getPerformanceMeasureEntry(measureKey);
  const totalDuration = duration + durationOffset;
  const properties = { ...eventProperties, durationOffset };

  const performanceEvent: PerformanceEvent = {
    type: 'Performance',
    name: eventName,
    occurredAt: Date.now().toString(),
    duration: totalDuration,
    startTime,
    properties: filterUnsafeProperties<PerformanceEventProperties>(eventName, properties),
  };

  reportTelemetryEvent(performanceEvent);
};

export const endPerformanceMeasure: EndAndReportPerformanceMeasure = (options) => {
  if (!isBrowserPerformanceApiAvailable()) {
    return;
  }

  const { measureKey, fromNavigationStart } = options;

  endMeasure(measureKey, fromNavigationStart);
};

/**
 * Starts a performance event. Underneath the hood, this adds a start event to the browser's performance events.
 * You can verify this mark locally by calling window.performance.getEntries() with your browser devtools.
 * @param eventName The event's name as defined by the PerformanceEventName type. Used to mark the performance
 * event on the browser, unless a measureName is also passed.
 * @param measureName A measureName for the performance event that will be concatenated with the eventName
 * to mark the performance event on the browser if passed. For example, if eventName = UiComponentMeasure and
 * measureName = feed_mount, then marks and measures on the browser will be recorded using the name
 * 'ui_component_measure_feed_mount'
 */
export const startPerformanceMeasure: StartPerformanceMeasure = (
  eventName: PerformanceEventName,
  measureName?: string
): PerformanceMeasure => {
  const measure = { eventName, measureKey: '' };
  if (!isBrowserPerformanceApiAvailable()) {
    return measure;
  }

  const measureKey = getMeasureKey(eventName, measureName);
  startMeasure(measureKey);

  return { ...measure, measureKey };
};

const measureEmoji: Record<PerformanceEventName, string> = {
  ['acquire_token_for_resource']: '🔑',
  ['amplify_attach_files']: '🖇',
  ['amplify_create_draft']: '📔',
  ['api_fetch']: '✈️',
  ['auth_bootstrap']: '🚀',
  ['auth_page_load']: '🚀',
  ['azure_video_load']: '📽️',
  ['bootstrap']: '🚀',
  ['bootstrap_navigation']: '🚀',
  ['cached_first_page_load']: '🎨',
  ['convert_interop_to_content_state']: '🔄',
  ['cumulative_layout_shift']: '↔️',
  ['feed_load_older_spinner']: '🚀',
  ['file_attach']: '📄',
  ['file_browser_load']: '📄',
  ['file_picker_load']: '📄',
  ['file_upload']: '📄',
  ['file_download']: '📄',
  ['first_contentful_paint']: '🎨',
  ['first_input_delay']: '🕹',
  ['first_meaningful_paint']: '🎨',
  ['first_page_load']: '🎨',
  ['first_paint']: '🎨',
  ['image_load']: '🖼️',
  ['largest_contentful_paint']: '📲',
  ['load_teams_meeting_details']: '✈️',
  ['managed_api_action']: '✈️',
  ['message_post']: '✉️',
  ['meta_os_hub_initialize']: '⚙',
  ['moment_home_carousel_load']: '🎠',
  ['moments_navigation']: '🎠',
  ['moment_user_carousel_load']: '🎠',
  ['page_load']: '🎨',
  ['perceived_image_load']: '🖼️',
  ['renderer_bundle_load']: '🚀',
  ['resource_timing']: '🚀',
  ['service_worker_disable']: '⚙',
  ['service_worker_registration']: '⚙',
  ['sharepoint_auth_token_fetch']: '🚀',
  ['teams_liveevent_video_load']: '📹',
  ['time_to_first_byte']: '⏲',
  ['ui_component_measure']: '🖌️',
  ['webpart_config']: '🚀',
};

// In the case of ui component and api action measurements, we will mark events on the browser
// using a measure name that specifies the component or action, concatenated with the generic event name
// For example, if we pass eventName = UiComponentMeasure, measureName = feed_mount, then
// on the browser we will mark and measure using the following key.
// 'ui_component_measure_feed_mount_b2d3aa1c-b686-4e42-b8ae-4d7fe706a923'
const getMeasureKey = (eventName: PerformanceEventName, measureName?: string): string => {
  const emoji = measureEmoji[eventName];
  const qualifiedMeasureName = measureName ? `${eventName}_${measureName}` : eventName;
  const uniqueIdentifier = uuidv4();

  return `${emoji} ${qualifiedMeasureName}_${uniqueIdentifier}`;
};

const startMeasure = (measureKey: string): void => {
  addPerformanceMark(getStartMarkName(measureKey));
};

type EndMeasure = (measureKey: string, fromNavigationStart?: boolean) => void;
export const endMeasure: EndMeasure = (measureKey: string, fromNavigationStart?: boolean) => {
  if (fromNavigationStart) {
    window.performance.measure(measureKey);

    return;
  }

  addPerformanceMark(getEndMarkName(measureKey));

  try {
    window.performance.measure(measureKey, getStartMarkName(measureKey), getEndMarkName(measureKey));
  } catch (err) {
    throw new Error(
      `Performance mark ${getStartMarkName(measureKey)} not found. Did you call startMeasure before
            reportPerformance? Exception: ${JSON.stringify(err.message)}`
    );
  }
};

const isBrowserPerformanceApiAvailable = () =>
  typeof window !== undefined && window.performance && !!window.performance.mark;

const addPerformanceMark = (markName: string) => {
  window.performance.mark(markName);
};

const getStartMarkName = (measureKey: string): string => `${measureKey}_start`;

const getEndMarkName = (measureKey: string): string => `${measureKey}_end`;

type GetPerformanceMeasureEntry = (measureKey: string) => PerformanceEntry;
export const getPerformanceMeasureEntry: GetPerformanceMeasureEntry = (measureKey) => {
  const measures = window.performance.getEntriesByName(measureKey);
  if (measures.length === 0) {
    throw new Error(`No entries for eventName ${measureKey} were found`);
  }

  return measures[measures.length - 1];
};

type ReportWebVitalMetric = (metric: Metric, name: PerformanceEventName) => void;
const reportWebVitalMetric: ReportWebVitalMetric = ({ value }, name) => {
  const performanceEvent: PerformanceEvent = {
    type: 'Performance',
    name,
    occurredAt: Date.now().toString(),
    properties: {
      duration: value,
    },
  };
  enqueueAndThrottleReportLogEvents('Performance', [performanceEvent]);
};

export type ReportBootstrapNavigationAndResourceEvents = () => void;
export const reportBootstrapNavigationAndResourceEvents: ReportBootstrapNavigationAndResourceEvents = () => {
  getLCP((metric) => reportWebVitalMetric(metric, 'largest_contentful_paint'));
  getCLS((metric) => reportWebVitalMetric(metric, 'cumulative_layout_shift'));
  getFID((metric) => reportWebVitalMetric(metric, 'first_input_delay'));
  getTTFB((metric) => reportWebVitalMetric(metric, 'time_to_first_byte'));

  if (!isBrowserPerformanceApiAvailable()) {
    return;
  }

  const { navigationStart, domContentLoadedEventEnd } = !!window.performance.timing && window.performance.timing;

  const performanceEventsToEnqueue: PerformanceEvent[] = [];

  const navigationEvent = getNavigationEvent(navigationStart);
  if (navigationEvent) {
    performanceEventsToEnqueue.push(navigationEvent);
  }

  const firstPaintEvent = getFirstPaintEvent(navigationStart, domContentLoadedEventEnd);
  if (firstPaintEvent) {
    performanceEventsToEnqueue.push(firstPaintEvent);
  }

  const firstContentfulPaintEvent = getFirstContentfulPaintEvent();
  if (firstContentfulPaintEvent) {
    performanceEventsToEnqueue.push(firstContentfulPaintEvent);
  }

  if (performanceEventsToEnqueue.length > 0) {
    enqueueAndThrottleReportLogEvents('Performance', performanceEventsToEnqueue);
  }
};

const getFirstPaintEvent = (navigationStart: number, domContentLoadedEventEnd: number): PerformanceEvent | null => {
  // Try to get the Chrome specific entry
  const firstPaintEntries: PerformanceEntry[] = window.performance.getEntriesByName('first-paint');
  const firstPaintEntry = firstPaintEntries && firstPaintEntries[0];

  // If not available, use other browsers' approximation (if timing events are available)
  const firstPaintMeasurement =
    (firstPaintEntry && firstPaintEntry.startTime) ||
    (domContentLoadedEventEnd && navigationStart && domContentLoadedEventEnd - navigationStart);

  return firstPaintMeasurement
    ? {
        type: 'Performance',
        name: 'first_paint',
        occurredAt: Date.now().toString(),
        duration: 0,
        startTime: firstPaintMeasurement,
        properties: {
          isMeasurementApproximated: !firstPaintEntry,
        },
      }
    : null;
};

const getFirstContentfulPaintEvent = (): PerformanceEvent | null => {
  const firstContentfulPaintEntries: PerformanceEntry[] = window.performance.getEntriesByName('first-contentful-paint');
  const firstContentfulPaintMeasurement =
    firstContentfulPaintEntries && firstContentfulPaintEntries[0] && firstContentfulPaintEntries[0].startTime;

  return firstContentfulPaintMeasurement
    ? {
        type: 'Performance',
        name: 'first_contentful_paint',
        occurredAt: Date.now().toString(),
        duration: 0,
        startTime: firstContentfulPaintMeasurement,
        properties: {},
      }
    : null;
};

const getNavigationEvent = (navigationStart: number): PerformanceEvent | null => {
  const navigationEntries = window.performance.getEntriesByType('navigation');
  if (navigationEntries && navigationEntries[0]) {
    const {
      domComplete,
      domContentLoadedEventEnd,
      domContentLoadedEventStart,
      domInteractive,
      duration,
      fetchStart,
      connectEnd,
      connectStart,
      domainLookupStart,
      domainLookupEnd,
      loadEventEnd,
      loadEventStart,
      redirectEnd,
      redirectStart,
      requestStart,
      responseEnd,
      responseStart,
      startTime,
      unloadEventEnd,
      unloadEventStart,
      workerStart,
    } = navigationEntries[0] as PerformanceNavigationTiming;

    return {
      name: 'bootstrap_navigation',
      type: 'Performance',
      occurredAt: Date.now().toString(),
      duration,
      startTime,
      properties: roundNavigationProperties({
        connectEnd,
        connectStart,
        domainLookupStart,
        domainLookupEnd,
        domComplete,
        domContentLoadedEventEnd,
        domContentLoadedEventStart,
        domInteractive,
        fetchStart,
        loadEventEnd,
        loadEventStart,
        redirectEnd,
        redirectStart,
        requestStart,
        responseEnd,
        responseStart,
        unloadEventEnd,
        unloadEventStart,
        workerStart,
        navigationStart: navigationStart || 0,
      }),
    };
  }

  return null;
};

const roundNavigationProperties = (
  properties: NavigationPerformanceEventProperties
): NavigationPerformanceEventProperties =>
  Object.keys(properties).reduce((rounded: NavigationPerformanceEventProperties, key) => {
    rounded[key] = Math.round(properties[key]);

    return rounded;
  }, properties);
