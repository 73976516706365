import '../../publicPath';

import loadPolyfills from '../2-polyfill';

import authBootstrap from './auth';
import { configure as configureTelemetry } from './telemetry';

(async () => {
  await loadPolyfills();
  await configureTelemetry();
  await authBootstrap();
})();
