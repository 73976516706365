export class TokenExpirationParsingError extends Error {
  public name: string;

  constructor(message: string) {
    super(message);

    this.name = 'TokenExpirationParsingError';

    Object.setPrototypeOf(this, TokenExpirationParsingError.prototype);
  }
}
