import { ClientContext } from '@ms/yammer-telemetry-support';

import { getUserAgentDetails } from '../userAgent';

interface ClientContextOptions {
  readonly hostClientType?: string;
  readonly hostAppName?: string;
  readonly hostSessionId?: string;
  readonly hostLaunchId?: string;
  readonly hostRingId?: string;
  readonly meetingTenantId?: string;
}
export type GetClientContext = (options?: ClientContextOptions) => ClientContext;

const getClientContext: GetClientContext = (clientContextOptions) => {
  const { osName, deviceType } = getUserAgentDetails();

  return {
    osName,
    deviceType,
    ...clientContextOptions,
  };
};

export default getClientContext;
