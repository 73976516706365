import { GetEventsForPerformanceEntries } from '../types';

import { getLongAnimationFramePerformanceEvent } from './getLongAnimationFramePerformanceEvent';
import { PerformanceLongAnimationFrameTiming } from './types';

const reportingThresholdInMilliseconds = 150;

export const getEventsForLongAnimationFrameEntries: GetEventsForPerformanceEntries = (entries) =>
  entries
    .filter((entry) => entry.duration > reportingThresholdInMilliseconds)
    .map((entry) => getLongAnimationFramePerformanceEvent(entry as PerformanceLongAnimationFrameTiming));
