import { writeHostingCookies } from '@ms/yammer-web-support/dist/hosting';
import { basenameForWebApp } from '@ms/yammer-web-support/dist/location';

type WriteCookies = (yammerToken: string) => void;
export const writeCookies: WriteCookies = (yammerToken) => {
  const ringInfoCookiePath = basenameForWebApp;
  const shouldSetRingInfoCookie = true;
  const shouldSetSameSite = true;

  writeHostingCookies({
    yammerToken,
    ringInfoCookiePath,
    shouldSetSameSite,
    shouldSetRingInfoCookie,
  });
};
