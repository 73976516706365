import { CurrentNetworkContext, NetworkType } from '@ms/yammer-data/dist/state/types';

import { basenameForWebApp } from './basename';
import { trimBasenameFromUrlPath } from './trimBasenameFromUrlPath';
import { trimLeadingAndTrailingSlash } from './trimSlash';
import { getUrlRootPath } from './urlRootPath';
import { UrlRootPath, networkSchemaRootPaths } from './urlRootPath.types';

type GetTenantFromPath = (urlPath: string) => boolean;
const isLegacyTenantPath: GetTenantFromPath = (urlPath) => {
  const isWebApp = urlPath.indexOf(basenameForWebApp) >= 0;
  if (!isWebApp) {
    return false;
  }

  return getUrlRootPath(urlPath) === 'other';
};

const networkTypes = {
  [UrlRootPath.network]: NetworkType.External,
  [UrlRootPath.org]: NetworkType.Canonical,
};

export type GetNetworkContext = (urlPath: string) => CurrentNetworkContext;
export const getNetworkContext: GetNetworkContext = (urlPath) => {
  const urlPathWithoutBasename = trimBasenameFromUrlPath(urlPath);
  const urlPathParts = trimLeadingAndTrailingSlash(urlPathWithoutBasename).split('/');

  const isNetworkContextUrlSchema =
    urlPathParts.length >= 2 && networkSchemaRootPaths.includes(urlPathParts[0] as UrlRootPath);
  if (isNetworkContextUrlSchema) {
    const networkTypePart = urlPathParts[0] as UrlRootPath.network | UrlRootPath.org;
    const permalink = urlPathParts[1];

    return {
      networkType: networkTypes[networkTypePart],
      permalink,
      networkPath: `${networkTypePart}/${urlPathParts[1]}`,
    };
  }

  if (isLegacyTenantPath(urlPath)) {
    const tenant = urlPathParts[0];

    return {
      networkType: NetworkType.Canonical,
      permalink: tenant,
      networkPath: 'org/' + tenant,
    };
  }

  return null;
};
