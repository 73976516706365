import { getCurrentPageName } from '@ms/yammer-telemetry-support';

import { PerformanceEvent } from '../../events';

import { getSanitizedInvoker, getUrlWithoutQueryParams } from './getSanitizedProperty';
import { PerformanceLongAnimationFrameTiming } from './types';

const getCustomProperties = ({
  scripts,
  duration,
  blockingDuration,
  startTime,
  styleAndLayoutStart,
  renderStart,
}: PerformanceLongAnimationFrameTiming) => ({
  blocking: blockingDuration,
  work: renderStart ? renderStart - startTime : duration,
  render: renderStart ? startTime + duration - renderStart : 0,
  prelayout: styleAndLayoutStart ? styleAndLayoutStart - renderStart : 0,
  styleAndLayout: styleAndLayoutStart ? startTime + duration - styleAndLayoutStart : 0,
  ...scripts.reduce(
    (data, script) => {
      data.si += (data.si ? '|' : '') + getSanitizedInvoker(script.invoker);
      data.set += (data.set ? '|' : '') + script.entryType;
      data.surl += (data.surl ? '|' : '') + (getUrlWithoutQueryParams(script.sourceURL) || 'NA');
      data.sfn += (data.sfn ? '|' : '') + (script.sourceFunctionName || 'NA');
      data.scp += (data.scp ? '|' : '') + script.sourceCharPosition;
      data.sd += (data.sd ? '|' : '') + script.duration;
      data.sit += (data.sit ? '|' : '') + script.invokerType;
      data.sfsl += (data.sfsl ? '|' : '') + script.forcedStyleAndLayoutDuration;
      data.ses += (data.ses ? '|' : '') + script.executionStart;
      data.spd += (data.spd ? '|' : '') + script.pauseDuration;

      return data;
    },
    { si: '', set: '', surl: '', sfn: '', scp: '', sd: '', sit: '', sfsl: '', ses: '', spd: '' }
  ),
});

const getTimingProperties = (entry: PerformanceLongAnimationFrameTiming) => {
  const { firstUIEventTimestamp, renderStart, styleAndLayoutStart } = entry;
  const customProperties = getCustomProperties(entry);

  return {
    payload: JSON.stringify({
      firstUIEventTimestamp,
      renderStart,
      styleAndLayoutStart,
      ...customProperties,
    }),
    page: getCurrentPageName(),
  };
};

type GetLongAnimationFramePerformanceEvent = (entry: PerformanceLongAnimationFrameTiming) => PerformanceEvent;
export const getLongAnimationFramePerformanceEvent: GetLongAnimationFramePerformanceEvent = (entry) => ({
  type: 'Performance',
  name: 'long_animation_frame_timing',
  duration: entry.duration,
  startTime: entry.startTime,
  occurredAt: Date.now().toString(),
  properties: getTimingProperties(entry),
});
