import { InfoEvent, InfoEventName, InfoEventProperties } from '@ms/yammer-telemetry';

import { reportTelemetryEvent, reportTelemetryEventNow } from '../report';

import { filterUnsafeProperties } from './eventPropertyCheck';

interface ReportInfoOptions {
  /**
   * The event's name as defined in the InfoEventName enum. This will be logged in the event's
   * 'EventName' column in the 'yamjs' table.
   */
  readonly eventName: InfoEventName;
  /**
   * An optional set of custom properties to log for the event. These properties must be defined in
   * an interface in the telemetry types file and added as part of the InfoEventProperties type
   * (see the README about the process for adding new custom properties). Each value passed here will
   * be logged into a property-named column in the 'yamjs' table. There may be extra work involved to
   * add a property column if it does not already exist in the 'yamjs' table (see README).
   */
  readonly eventProperties?: InfoEventProperties;
}

const getInfoEvent = (options: ReportInfoOptions): InfoEvent => {
  const { eventName, eventProperties = {} } = options;

  return {
    type: 'Info',
    name: eventName,
    occurredAt: Date.now().toString(),
    properties: filterUnsafeProperties<InfoEventProperties>(eventName, eventProperties),
  };
};

type ReportInfo = (options: ReportInfoOptions) => void;
export const reportInfo: ReportInfo = (options) => {
  const infoEvent = getInfoEvent(options);
  reportTelemetryEvent(infoEvent);
};

type ReportInfoNow = (options: ReportInfoOptions) => Promise<void>;
export const reportInfoNow: ReportInfoNow = async (options) => {
  const infoEvent = getInfoEvent(options);
  await reportTelemetryEventNow(infoEvent);
};
