import {
  AnalyticsEventProperties,
  AnalyticsV2Event,
  ErrorEventProperties,
  InfoEventProperties,
  TelemetryPropertyValueType,
} from '@ms/yammer-telemetry';
import { PerformanceEventProperties } from '@ms/yammer-telemetry-support';

export interface ApplicationContextInput {
  readonly ClientId?: string;
  readonly first_party_client: string;
  readonly UserId?: string;
  readonly NetworkId?: string;
  readonly client_load_id: string;
  readonly client_load_time: string;
  readonly yamjs_revision: string;
  readonly environment: string;
}

export type AnalyticsEventPropertiesInput = Record<
  string | keyof AnalyticsEventProperties | keyof ApplicationContextInput,
  TelemetryPropertyValueType | null
>;

export type LogEventPropertiesInput = Record<
  | string
  | keyof InfoEventProperties
  | keyof ErrorEventProperties
  | keyof PerformanceEventProperties
  | keyof ApplicationContextInput,
  TelemetryPropertyValueType | null
>;

export enum TelemetryEventInputType {
  Analytics = 'Analytics',
  AnalyticsV2 = 'AnalyticsV2',
  Log = 'Log',
}

export interface AnalyticsEventInput {
  readonly name: string;
  readonly properties: AnalyticsEventPropertiesInput;
}

export type AnalyticsV2EventInput = AnalyticsV2Event;

export interface LogEventInput {
  readonly EventName: string;
  readonly LogType: string;
  readonly ClientTimeStamp: number;
  readonly ClientId?: string;
  readonly DeviceId?: string;
  readonly UserId?: number;
  readonly NetworkId?: number;
  readonly Parameters: LogEventPropertiesInput;
}

export interface ReportLogEventsInput {
  readonly errorEvents: LogEventInput[];
  readonly infoEvents: LogEventInput[];
  readonly performanceEvents: LogEventInput[];
}

export interface ReportAnalyticsEventsInput {
  readonly events?: AnalyticsEventInput[];
}

export type EventPropertiesInput = AnalyticsEventPropertiesInput | LogEventPropertiesInput;
