/* eslint-disable @typescript-eslint/no-explicit-any */
import { error as logErrorToConsole } from '@ms/yammer-console-logging';
import { getTelemetryHostingConfig } from '@ms/yammer-telemetry-store';
import { ErrorEventProperties, ErrorEvent as TelemetryErrorEvent } from '@ms/yammer-telemetry-support';
import { AcquireTokenAuthError, SharePointAuthError } from '@ms/yammer-web-authenticators';
import { isNil } from 'lodash';

import { ApiError, GraphqlApiError, SharePointApiError } from '../../api/errors';
import { SearchResultThreadRenderError } from '../../state/types';
import { reportTelemetryEvent, reportTelemetryEventNow } from '../report';

import { filterUnsafeProperties } from './eventPropertyCheck';

export type ReportableError = Error | TelemetryErrorEvent | PromiseRejectionEvent;

const getTelemetryErrorEventProperties = (error: ReportableError, eventProperties: ErrorEventProperties) => {
  if (error instanceof GraphqlApiError) {
    return {
      afdRef: error.afdRef,
      requestId: error.requestId,
      correlationId: error.correlationId,
      operationName: error.operationName,
      errorCode: 'GraphqlApiError',
      reason: error.reason,
      status: error.status,
      requestType: error.requestType,
      apiName: error.apiName,
      initiator: error.initiator,
      ...eventProperties,
    };
  }

  if (error instanceof SharePointApiError) {
    return {
      requestType: error.requestType,
      apiName: error.apiName,
      status: error.status,
      errorCode: 'SharePointApiError',
      initiator: error.initiator,
      requestId: error.requestId,
      afdRef: error.afdRef,
      spRequestGuid: error.spRequestGuid,
      ...eventProperties,
    };
  }

  if (error instanceof ApiError) {
    return {
      requestType: error.requestType,
      apiName: error.apiName,
      status: error.status,
      errorCode: 'ApiError',
      initiator: error.initiator,
      requestId: error.requestId,
      afdRef: error.afdRef,
      ...eventProperties,
    };
  }

  if (error instanceof ErrorEvent) {
    return {
      line: error.lineno,
      col: error.colno,
      file: error.filename,
      ...eventProperties,
    };
  }

  if (error instanceof PromiseRejectionEvent) {
    return {
      reason: error.reason,
      ...eventProperties,
    };
  }

  if (error instanceof AcquireTokenAuthError) {
    return {
      attempts: error.attempts,
      errorMessage: error.errorMessage,
      reason: error.errorCode,
      subError: error.subError,
      ...eventProperties,
    };
  }

  if (error instanceof SharePointAuthError) {
    return {
      name: error.name,
      status: error.status,
      correlationId: error.correlationId,
      ...eventProperties,
    };
  }

  if (error instanceof SearchResultThreadRenderError) {
    return {
      threadId: error.threadId,
      ...eventProperties,
    };
  }

  return eventProperties;
};

type GetErrorMessage = (error: any) => string | undefined;
export const getErrorMessage: GetErrorMessage = (error) => {
  if (isNil(error)) {
    return;
  }

  if (typeof error === 'object') {
    if ('message' in error) {
      return error.message;
    }

    return JSON.stringify(error);
  }

  return error.toString();
};

type GetErrorReason = (error: any) => string | undefined;
export const getErrorReason: GetErrorReason = (error) => {
  if (!error) {
    return;
  }

  return error.reason || error.errorCode;
};

const getErrorStack = (error: ReportableError): string => {
  const errorObject = error instanceof ErrorEvent ? error.error : error;

  return (errorObject && errorObject.stack) || '';
};

const getErrorName = (error: ReportableError): string => {
  if (error instanceof ErrorEvent) {
    return 'ErrorEvent';
  }

  if (error instanceof PromiseRejectionEvent) {
    return 'PromiseRejectionEvent';
  }

  return error.name;
};

const getTelemetryErrorEvent = (options: ReportErrorOptions): TelemetryErrorEvent => {
  const { error, eventProperties = {} } = options;
  const name = getErrorName(error);

  return {
    type: 'Error',
    name,
    occurredAt: Date.now().toString(),
    message: getErrorMessage(error) || '',
    stack: getErrorStack(error),
    properties: filterUnsafeProperties<ErrorEventProperties>(
      name,
      getTelemetryErrorEventProperties(error, eventProperties)
    ),
  };
};

export interface ReportErrorOptions {
  /**
   * One of the following error objects can be reported.
   * -  An [Error](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error)
   *    object consisting of message and stack trace for the error. This object is the preferred way to report errors.
   * -  An [ErrorEvent](https://developer.mozilla.org/en-US/docs/Web/API/ErrorEvent) object
   *    representing event providing information related to errors in scripts or in files.
   *    This property should be passed whenever it is already available.
   */
  readonly error: ReportableError;
  /**
   * The various properties that are used to describe details around a reported error. These are
   * used to glean details about the circumstance that led to the error.
   */
  readonly eventProperties?: ErrorEventProperties;
  /**
   * Whether or not the error should be sent to the console as well. Defaults to true.
   */
  readonly sendToConsole?: boolean;
}

interface ReportUnknownErrorOptions extends Omit<ReportErrorOptions, 'error'> {
  readonly error: unknown;
}

export const isReportableError = (error: unknown): error is ReportableError =>
  error instanceof Error || error instanceof ErrorEvent || error instanceof PromiseRejectionEvent;

type CastToReportableError = (exception: unknown) => ReportableError;
const castToReportableError: CastToReportableError = (exception) => {
  if (isReportableError(exception)) {
    return exception;
  }

  if (isNil(exception)) {
    return new Error();
  }

  const message = typeof exception === 'object' ? JSON.stringify(exception) : String(exception);

  return new Error(message);
};

type LogErrorToConsoleIfNecessary = (options: ReportErrorOptions) => void;
const logErrorToConsoleIfNecessary: LogErrorToConsoleIfNecessary = (options) => {
  const { sendToConsole = true } = options;
  const telemetryHostingConfig = getTelemetryHostingConfig();

  if (telemetryHostingConfig.hostingEnvironment === 'dev' && sendToConsole) {
    logErrorToConsole(options);
  }
};

interface ReportError {
  (options: ReportErrorOptions): void;
  (options: ReportUnknownErrorOptions): void;
}
export const reportError: ReportError = (options: ReportErrorOptions | ReportUnknownErrorOptions) => {
  const { error: exception, ...additionalOptions } = options;
  const error = castToReportableError(exception);
  const reportOptions: ReportErrorOptions = { ...additionalOptions, error };

  logErrorToConsoleIfNecessary(reportOptions);

  const errorEvent = getTelemetryErrorEvent(reportOptions);
  reportTelemetryEvent(errorEvent);
};

interface ReportErrorNow {
  (options: ReportErrorOptions): Promise<void>;
  (options: ReportUnknownErrorOptions): Promise<void>;
}
export const reportErrorNow: ReportErrorNow = async (options: ReportErrorOptions | ReportUnknownErrorOptions) => {
  const { error: exception, ...additionalOptions } = options;
  const error = castToReportableError(exception);
  const reportOptions: ReportErrorOptions = { ...additionalOptions, error };

  logErrorToConsoleIfNecessary(reportOptions);

  const errorEvent = getTelemetryErrorEvent(reportOptions);
  await reportTelemetryEventNow(errorEvent);
};
