/* eslint-disable @ms/forbid-file-names */
import { getBasenameForUrlPath } from './getBasenameForUrlPath';
import { trimLeadingAndTrailingSlash } from './trimSlash';
import { UrlRootPath, networkSchemaRootPaths } from './urlRootPath.types';

const trimBasename = (urlPath: string, basename: string) => urlPath.substring(basename.length);

export type TrimBasenameFromUrlPath = (urlPath: string) => string;
export const trimBasenameFromUrlPath: TrimBasenameFromUrlPath = (urlPath) => {
  const basename = getBasenameForUrlPath(urlPath);

  return basename ? trimBasename(urlPath, basename) : urlPath;
};

const isNetworkSchemaUrl = (urlPath: string): boolean => {
  const urlPathParts = trimLeadingAndTrailingSlash(trimBasenameFromUrlPath(urlPath)).split('/');

  return networkSchemaRootPaths.includes(urlPathParts[0] as UrlRootPath) && urlPathParts.length >= 2;
};

export const trimBasenameAndNetworkFromUrlPath = (urlPath: string) => {
  if (!isNetworkSchemaUrl(urlPath)) {
    return trimBasenameFromUrlPath(urlPath);
  }

  const urlPathParts = trimLeadingAndTrailingSlash(trimBasenameFromUrlPath(urlPath)).split('/');
  const urlPathWithoutNetwork = urlPathParts.slice(2);

  return `/${urlPathWithoutNetwork.join('/')}`;
};
