import { setAuthenticator } from '@ms/yammer-data/dist/authentication';
import { NetworkType } from '@ms/yammer-data/dist/state/types';
import { getNetworkContext } from '@ms/yammer-web-support/dist/location';

import config from '../config';

import { reportAuthError } from './reportAuthError';

const getAuthenticator = async () => {
  const networkContext = getNetworkContext(window.location.pathname);
  let tenant = config.msal.tenant;
  if (networkContext?.networkType === NetworkType.Canonical) {
    tenant = networkContext.permalink;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (config as any).adal = config.msal;
  const { default: MsalRedirectAuthenticator } = await import(
    /* webpackChunkName: "4-auth-msal" */ '@ms/yammer-web-authenticators/dist/redirect/MsalRedirectAuthenticator'
  );

  return new MsalRedirectAuthenticator({ ...config.msal, tenant, onError: reportAuthError });
};

export type SetupAuthenticator = () => ReturnType<typeof getAuthenticator>;
export const setupAuthenticator: SetupAuthenticator = async () => {
  const authenticator = await getAuthenticator();

  setAuthenticator(authenticator);

  return authenticator;
};
