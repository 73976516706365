export class HandleTokenInUrlError extends Error {
  public name: string;

  constructor(message: string) {
    super(message);

    this.name = 'HandleTokenInUrlError';

    Object.setPrototypeOf(this, HandleTokenInUrlError.prototype);
  }
}
