import { reportError } from '../api';

import { getUnhandledErrorEventsToIgnore } from './unhandledEventsToIgnore';

type AddUnhandledErrorEventListener = () => void;
export const addUnhandledErrorEventListener: AddUnhandledErrorEventListener = () => {
  window.addEventListener('error', (errorEvent) => {
    if (!getUnhandledErrorEventsToIgnore().includes(errorEvent.message)) {
      reportError({ error: errorEvent, eventProperties: { errorCode: 'UnhandledError' } });
    }
  });
};

type AddUnhandledRejectionEventListener = () => void;
export const addUnhandledRejectionEventListener: AddUnhandledRejectionEventListener = () => {
  window.addEventListener('unhandledrejection', (rejectionEvent) => {
    reportError({ error: rejectionEvent, eventProperties: { errorCode: 'UnhandledRejection' } });
  });
};
