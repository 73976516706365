import { sizeof } from 'sizeof';

interface BatchEventsResult {
  readonly eventsEndIndex: number;
  readonly cumulativeSizeInBytes: number;
}
type GetEventsBatchInfo = <T>(events: T[], startIndex: number, batchSizeInBytes: number) => BatchEventsResult;
export const getEventsBatchInfo: GetEventsBatchInfo = (events, startIndex, batchSizeInBytes) => {
  let cumulativeSizeInBytes = 0;
  let index = startIndex;

  while (index < events.length && cumulativeSizeInBytes < batchSizeInBytes) {
    cumulativeSizeInBytes += sizeof(events[index]);
    index = index + 1;
  }

  return {
    eventsEndIndex: index,
    cumulativeSizeInBytes,
  };
};
