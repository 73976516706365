type GetNetworkInformation = () => Partial<NetworkInformation>;

export const getNetworkInformation: GetNetworkInformation = () => {
  const connection = navigator.connection;

  if (!connection) {
    return {};
  }

  const { downlink, downlinkMax, effectiveType, rtt, saveData, type } = connection;

  return { downlink, downlinkMax, effectiveType, rtt, saveData, type };
};
