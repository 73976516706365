import { error } from '@ms/yammer-console-logging';

import { getPolyfills as getWindowPolyfills } from './polyfills/window';

const loadPolyfills: () => Promise<any[]> = async () => {
  const polyfills: Promise<any>[] = [];
  polyfills.push(...getWindowPolyfills());

  return Promise.all(polyfills);
};

const polyfillsLoader = async () => {
  try {
    await loadPolyfills();
  } catch (err: unknown) {
    error('Failed to polyfill:', err);
  }
};

export default polyfillsLoader;
