import { PerformanceEvent } from '../events';

import { getEventsForEventTimingEntries } from './eventTiming';
import { getEventsForLongAnimationFrameEntries } from './longAnimationFrameTiming';
import { getEventsForResourceEntries } from './resourceTiming';
import { GetEventsForPerformanceEntries, PerformanceEntryType } from './types';

const mappers: Record<PerformanceEntryType, GetEventsForPerformanceEntries> = {
  resource: getEventsForResourceEntries,
  'long-animation-frame': getEventsForLongAnimationFrameEntries,
  event: getEventsForEventTimingEntries,
  'first-input': getEventsForEventTimingEntries,
};

const isPerformanceObserverAvailable = () => 'performance' in window && 'PerformanceObserver' in window;

const getSupportedEntryTypes = (enableLongAnimationFrameTimingReporting: boolean): PerformanceEntryType[] => {
  if (!enableLongAnimationFrameTimingReporting) {
    return ['resource'];
  }

  const entryTypes: PerformanceEntryType[] = ['resource', 'long-animation-frame', 'event', 'first-input'];

  return entryTypes.filter((entryType) => PerformanceObserver.supportedEntryTypes.includes(entryType));
};

type ReportCallback = (events: PerformanceEvent[]) => void;

const reportEventsForEntries = (
  entryTypes: PerformanceEntryType[],
  list: PerformanceObserverEntryList,
  reportCallback: ReportCallback
) => {
  entryTypes.forEach((entryType) => {
    const entries = list.getEntries().filter((entry) => entry.entryType === entryType);
    const getEventsForEntries = mappers[entryType];
    const events = getEventsForEntries(entries);
    if (events.length > 0) {
      reportCallback(events);
    }
  });
};

interface PerformanceObserverOptions {
  readonly enableLongAnimationFrameTimingReporting: boolean;
  readonly reportCallback: ReportCallback;
}

type ConfigurePerformanceObserver = (options: PerformanceObserverOptions) => void;
export const configurePerformanceObserver: ConfigurePerformanceObserver = ({
  enableLongAnimationFrameTimingReporting,
  reportCallback,
}) => {
  if (!isPerformanceObserverAvailable()) {
    return;
  }

  const entryTypes: PerformanceEntryType[] = getSupportedEntryTypes(enableLongAnimationFrameTimingReporting);

  // One-time report for entries created before configuring PerformanceObserver
  reportEventsForEntries(entryTypes, window.performance, reportCallback);

  const perfObserver = new PerformanceObserver((list: PerformanceObserverEntryList, _obj: PerformanceObserver) =>
    reportEventsForEntries(entryTypes, list, reportCallback)
  );
  perfObserver.observe({ entryTypes });
};
