import { UAParser } from 'ua-parser-js';

const mobileDeviceTypes = ['mobile', 'tablet'];
const notFocusScrollableOsNames = ['iOS'];

export interface UserAgentDetails {
  readonly browserName?: string;
  readonly browserVersion?: string;
  readonly deviceType?: string;
  readonly osName?: string;
  readonly osVersion?: string;
}

type GetUserAgentDetails = () => UserAgentDetails;
export const getUserAgentDetails: GetUserAgentDetails = () => {
  const uaParser = new UAParser();
  const browser = uaParser.getBrowser();
  const os = uaParser.getOS();
  const device = uaParser.getDevice();

  return {
    browserName: browser.name,
    browserVersion: browser.version,
    deviceType: device.type,
    osName: os.name,
    osVersion: os.version,
  };
};

type IsMobileClient = () => boolean;
export const isMobileClient: IsMobileClient = () => {
  const { deviceType } = getUserAgentDetails();

  return deviceType ? mobileDeviceTypes.includes(deviceType) : false;
};

type IsTouchScreen = () => boolean;
export const isTouchScreen: IsTouchScreen = () => {
  const isPointerEventSupported = window.PointerEvent && 'maxTouchPoints' in navigator;
  const isCoarsePointer = 'matchMedia' in window && window.matchMedia('(any-pointer: coarse)').matches;
  const isTouchEventSupported = !!window.TouchEvent || !!window.ontouchstart;

  if (isPointerEventSupported) {
    return navigator.maxTouchPoints > 0;
  }

  return isCoarsePointer || isTouchEventSupported;
};

type IsFocusScrollableClient = () => boolean;
export const isFocusScrollableClient: IsFocusScrollableClient = () => {
  const { osName } = getUserAgentDetails();

  return osName ? !notFocusScrollableOsNames.includes(osName) : true;
};
