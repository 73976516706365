interface AcquireTokenAuthErrorOptions {
  readonly errorCode: string;
  readonly attempts?: number;
  readonly errorMessage?: string;
  readonly subError?: string;
}

export class AcquireTokenAuthError extends Error {
  public attempts: number;
  public errorCode: string;
  public errorMessage: string;
  public name: string;
  public subError?: string;

  constructor({
    errorCode,
    errorMessage = 'AcquireTokenAuthError',
    subError,
    attempts = 1,
  }: AcquireTokenAuthErrorOptions) {
    super(errorMessage);

    this.attempts = attempts;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.name = 'AcquireTokenAuthError';
    this.subError = subError;

    Object.setPrototypeOf(this, AcquireTokenAuthError.prototype);
  }
}
