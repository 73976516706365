interface SharePointAuthErrorParams {
  readonly name: string;
  readonly correlationId: string;
  readonly status: string;
  readonly message: string;
  readonly serverErrorCode?: number;
  readonly redirectUrl?: string;
  readonly isInteractionRequiredAuthError?: boolean;
}

export class SharePointAuthError extends Error {
  public name: string;
  public correlationId: string;
  public status: string;
  public message: string;
  public serverErrorCode?: number;
  public redirectUrl?: string;
  public isInteractionRequiredAuthError?: boolean;

  constructor({
    name,
    correlationId,
    status,
    message,
    serverErrorCode,
    redirectUrl,
    isInteractionRequiredAuthError,
  }: SharePointAuthErrorParams) {
    super();

    this.name = name;
    this.correlationId = correlationId;
    this.status = status;
    this.message = message;
    this.serverErrorCode = serverErrorCode;
    this.redirectUrl = redirectUrl;
    this.isInteractionRequiredAuthError = isInteractionRequiredAuthError;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, SharePointAuthError.prototype);
  }
}
