import { TelemetryEvent, isAnalyticsEvent, isAnalyticsV2Event } from '@ms/yammer-telemetry';

const addVisibilityStateToLogEvent = async (event: TelemetryEvent): Promise<TelemetryEvent> =>
  isAnalyticsEvent(event) || isAnalyticsV2Event(event)
    ? event
    : {
        ...event,
        properties: {
          ...event.properties,
          visibilityState: document.visibilityState,
        },
      };

const addUserAgentToAnalyticsV2Event = async (event: TelemetryEvent): Promise<TelemetryEvent> =>
  isAnalyticsV2Event(event)
    ? {
        ...event,
        properties: {
          ...event.properties,
          userAgent: navigator.userAgent,
        },
      }
    : event;

export type TelemetryMiddleware = (event: TelemetryEvent) => Promise<TelemetryEvent>;
const middlewares: TelemetryMiddleware[] = [addVisibilityStateToLogEvent, addUserAgentToAnalyticsV2Event];

export const applyMiddleware: TelemetryMiddleware = (event) =>
  middlewares.reduce(async (memoEvent, middleware) => middleware(await memoEvent), Promise.resolve(event));

export type AddMiddleware = (middleware: TelemetryMiddleware) => void;
export const addMiddleware: AddMiddleware = (middleware) => {
  middlewares.push(middleware);
};
