import jwtDecode from 'jwt-decode';
import { memoize } from 'lodash';

export interface TokenClaims {
  readonly oid: string;
  readonly tid: string;
  readonly upn: string;
  readonly idp: string | undefined;
}

const decodeToken = memoize((token) => jwtDecode(token));

type GetUserIdFromToken = (token: string) => string;
export const getUserIdFromToken: GetUserIdFromToken = (token) => {
  const { oid } = decodeToken(token);

  return oid;
};

type GetTenantIdFromToken = (token: string) => string;
export const getTenantIdFromToken: GetTenantIdFromToken = (token) => {
  const { tid } = decodeToken(token);

  return tid;
};

type GetTokenExpirationTimeInSeconds = (token: string) => number;
export const getTokenExpirationTimeInSeconds: GetTokenExpirationTimeInSeconds = (token) => {
  const { exp } = decodeToken(token);

  return exp;
};

type GetIdpFromToken = (token: string) => string;
export const getIdpFromToken: GetIdpFromToken = (token) => {
  const { idp } = decodeToken(token);

  return idp;
};

type GetIsEUDBFromToken = (token: string) => boolean;
export const getIsEUDBFromToken: GetIsEUDBFromToken = (token) => decodeToken(token).xms_tdbr === 'EU';

type GetTokenClaims = (token: string) => TokenClaims;
export const getTokenClaims: GetTokenClaims = (token) => decodeToken(token);

type IsTokenExpired = (token: string) => boolean;
export const isTokenExpired: IsTokenExpired = (token) => getTokenExpirationTimeInSeconds(token) * 1000 <= Date.now();

type IsJwtToken = (token: string) => boolean;
export const isJwtToken: IsJwtToken = (token) => {
  try {
    decodeToken(token);

    return true;
  } catch {
    return false;
  }
};
