import { parse } from 'url';

export type IsLoadedFromPWA = () => boolean;
export const isLoadedFromPWA: IsLoadedFromPWA = () => {
  const { query } = parse(window.location.href, true);
  const isPWA = query && query['isPWA'] === 'true';
  const isPWAWindowMediaStandalone = window.matchMedia && window.matchMedia('(display-mode: standalone)').matches;

  return isPWA || isPWAWindowMediaStandalone;
};
