import { acquireTokenWithTelemetry } from '@ms/yammer-data/dist/authentication';
import {
  endAndReportPerformanceMeasure,
  reportAuthPageLoad,
  reportError,
  reportInfo,
  startPerformanceMeasure,
} from '@ms/yammer-data/dist/telemetry';
import { isAuthRedirect, isIFrame, isMCASContext } from '@ms/yammer-web-authenticators';
import { getUrlRootPath } from '@ms/yammer-web-support/dist/location';

import { setupAuthenticator } from '../../auth';
import { redirectToMcasUrl } from '../../auth/redirectToMcasUrl';
import config from '../../config';
import { writeCookies } from '../../cookies';

const authBootstrap = async (): Promise<void> => {
  if (isMCASContext()) {
    redirectToMcasUrl();

    return;
  }

  const authenticator = await setupAuthenticator();

  await authenticator.initializeCurrentAccount();

  if (isAuthRedirect()) {
    return;
  }

  let endedWithError = false;
  const measure = startPerformanceMeasure('auth_bootstrap');
  try {
    const yammerToken = await acquireTokenWithTelemetry({
      scopes: [config.adal.resources.yammer],
      initiator: 'auth_bootstrap',
    });
    writeCookies(yammerToken);
  } catch (err) {
    endedWithError = true;
    reportError({
      error: err,
      eventProperties: { errorCode: 'AuthBootstrapFailed' },
    });
    throw err;
  } finally {
    const urlRootPath = getUrlRootPath(window.location.pathname);
    reportInfo({
      eventName: 'auth_bootstrap_info',
      eventProperties: {
        isExecutingInIFrame: isIFrame(),
        urlRootPath: getUrlRootPath(window.location.pathname),
      },
    });

    const eventProperties = {
      endedWithError,
      urlRootPath,
    };
    endAndReportPerformanceMeasure({
      ...measure,
      eventProperties,
    });

    reportAuthPageLoad({ eventProperties });
  }

  location.reload();
};

export default authBootstrap;
