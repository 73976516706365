import { getTelemetryHostingConfig } from '@ms/yammer-telemetry-store';

import { getOperationNameFromServerTiming, getResourceEntryType } from './resourceParser';
import { ResourceEntryType } from './types';

const graphqlOperationsToExclude = ['ReportTelemetryEventsClients'];

const isValidGraphqlOperation = (serverTiming: readonly PerformanceServerTiming[]) => {
  const operationName = getOperationNameFromServerTiming(serverTiming);

  return !!operationName && !graphqlOperationsToExclude.includes(operationName);
};

const isReportableGraphqlResourceEntry = (resourceEntry: PerformanceResourceTiming) =>
  !!resourceEntry.serverTiming && isValidGraphqlOperation(resourceEntry.serverTiming);

const isReportableJavaScriptResourceEntry = (resourceEntry: PerformanceResourceTiming) => {
  const scriptBaseUrl = getTelemetryHostingConfig().scriptBaseUrl;
  if (scriptBaseUrl) {
    return resourceEntry.name.indexOf(scriptBaseUrl) > -1;
  }

  return false;
};

const reportableResourceEntryTypes: ResourceEntryType[] = ['Flighting', 'GraphQL', 'JavaScript', 'Image'];

type IsReportableResourceEntry = (resourceEntry: PerformanceResourceTiming) => boolean;
export const isReportableResourceEntry: IsReportableResourceEntry = (resourceEntry) => {
  const resourceType = getResourceEntryType(resourceEntry);

  switch (resourceType) {
    case 'GraphQL':
      return isReportableGraphqlResourceEntry(resourceEntry as PerformanceResourceTiming);
    case 'JavaScript':
      return isReportableJavaScriptResourceEntry(resourceEntry);
    default:
      return reportableResourceEntryTypes.includes(resourceType);
  }
};
