import { HostingConfig } from '@ms/yammer-telemetry-support';

import { getQueryParameter } from '../location';

export type OverrideHostingConfig = (hostingConfig: HostingConfig) => HostingConfig;

export const overrideHostingEnvironment: OverrideHostingConfig = (hostingConfig) => {
  if (!getQueryParameter('branch')) {
    return hostingConfig;
  }

  return {
    ...hostingConfig,
    hostingEnvironment: 'dev',
  };
};

export const overrideHostname: OverrideHostingConfig = (hostingConfig) => ({
  ...hostingConfig,
  hostname: window.location.hostname,
});
