export class UnsupportedScopeError extends Error {
  public name: string;

  constructor(message: string) {
    super(message);

    this.name = 'UnsupportedScopeError';

    Object.setPrototypeOf(this, UnsupportedScopeError.prototype);
  }
}
