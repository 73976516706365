import { configureTelemetry } from '@ms/yammer-data/dist/telemetry';
import getClientContext from '@ms/yammer-web-support/dist/clientContext';
import { readHostingConfiguration } from '@ms/yammer-web-support/dist/hosting';
import { isLoadedFromPWA } from '@ms/yammer-web-support/dist/location';

import config from '../../config';

export const configure = async () => {
  const isPWA = isLoadedFromPWA();
  const clientContext = { ...getClientContext(), isPWA };
  configureTelemetry({
    config,
    hostingConfig: readHostingConfiguration(),
    skipNextCleanup: true,
    registerUnhandledEventsListener: true,
    isMsal: true,
    clientContext,
  });
};
