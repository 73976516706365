/* eslint-disable @typescript-eslint/no-explicit-any */
export type LogLevel = 'none' | 'error' | 'warn' | 'info' | 'debug';

const levelToNumber = {
  none: 0,
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
};

let configuredLogLevel: LogLevel = 'none';

const logger = (logLevel: LogLevel, ...args: any[]) => {
  const numericLogLevel = levelToNumber[logLevel];
  const numericConfigLevel = levelToNumber[configuredLogLevel];

  if (logLevel !== 'none' && numericLogLevel <= numericConfigLevel) {
    console[logLevel](...args);
  }
};

type SetLogLevel = (newLogLevel: LogLevel) => void;
export const setLogLevel: SetLogLevel = (newLogLevel) => {
  configuredLogLevel = newLogLevel;
};

export const error = (...args: any[]): void => {
  logger('error', ...args);
};

export const warn = (...args: any[]): void => {
  logger('warn', ...args);
};

export const info = (...args: any[]): void => {
  logger('info', ...args);
};

export const debug = (...args: any[]): void => {
  logger('debug', ...args);
};
