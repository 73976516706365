import { AnalyticsV2Event, BaseTelemetryEvent } from '@ms/yammer-telemetry';
import { enqueueTelemetryEvents, getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { getThrottledReportAnalyticsV2EventsInBatch } from './reportInBatches';

type EnqueueAndThrottleReportAnalyticsV2Events = <T extends BaseTelemetryEvent>(events: T[]) => void;
export const enqueueAndThrottleReportAnalyticsV2Events: EnqueueAndThrottleReportAnalyticsV2Events = <
  T extends BaseTelemetryEvent
>(
  events: T[]
) => {
  enqueueTelemetryEvents<T>('AnalyticsV2', events);
  const throttledReportAction = getThrottledReportAnalyticsV2EventsInBatch(
    getTelemetryClientConfig().eventReportIntervalInMilliseconds
  );

  throttledReportAction();
};

type ReportAnalyticsV2Event = (event: AnalyticsV2Event) => void;
export const reportAnalyticsV2Event: ReportAnalyticsV2Event = (event) => {
  enqueueAndThrottleReportAnalyticsV2Events([event]);
};
