/* eslint-disable @ms/forbid-file-names */
export const basenameForWebApp = '/main';
export const basenameForEmbedApp = '/embed';
export const basenameForTeamsApp = '/teams';
export const basenameForTeamsTabApp = '/teamstab';
export const basenameForTeamsMeetingApp = '/teamsmeeting';
export const basenameForTeamsBrandedExpApp = '/teams-branded-exp';

const basenames = [
  basenameForWebApp,
  basenameForTeamsApp,
  basenameForEmbedApp,
  basenameForTeamsTabApp,
  basenameForTeamsMeetingApp,
  basenameForTeamsBrandedExpApp,
];

export type GetBasenames = () => string[];
export const getBasenames: GetBasenames = () => basenames;
