import { parse as parseQueryString } from 'querystring';

export type GetQueryParameter = (name: string) => string | string[] | undefined;
export const getQueryParameter: GetQueryParameter = (name: string) => {
  const queryString = window.location.search.substring(1);
  const parsedQueryString = parseQueryString(queryString);

  return parsedQueryString[name];
};

export type GetQueryParameterFromUrl = (url: string, name: string) => string | undefined;
export const getQueryParameterFromUrl: GetQueryParameterFromUrl = (url: string, name: string) => {
  const urlObj = new URL(url);

  return urlObj.searchParams.get(name) ?? undefined;
};
