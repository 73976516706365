import {
  AnalyticsEvent,
  AnalyticsV2Event,
  ErrorEvent,
  InfoEvent,
  LogEvents,
  PerformanceEvent,
} from '@ms/yammer-telemetry';
import { dequeueTelemetryEvents, getTelemetryClientConfig } from '@ms/yammer-telemetry-store';
import { DebouncedFunc, throttle } from 'lodash';

import { batchReportAnalyticsEventInputs } from './batchAnalyticsEvents';
import { batchReportLogEventsInput } from './batchLogEvents';
import { getAuthToken } from './getAuthToken';
import { getReportAnalyticsEventsInput } from './reportAnalyticsEventsInput';
import { getReportLogEventsInput } from './reportLogEventsInput';
import {
  reportAnalyticsEventsNow,
  reportAnalyticsV2EventsNow,
  reportLogEventsNow,
} from './reportTelemetryEventsToServer';

type ReportTelemetryEventsInBatches = () => Promise<void>;
export const reportTelemetryEventsInBatches: ReportTelemetryEventsInBatches = async () => {
  await reportAnalyticsEventsInBatches();
  await reportLogEventsInBatches();
};

let throttledReportTelemetryEventsInBatch: DebouncedFunc<ReportTelemetryEventsInBatches>;

type ThrottledReportTelemetryEventsInBatch = (
  eventReportIntervalInMilliseconds: number
) => DebouncedFunc<ReportTelemetryEventsInBatches>;
export const getThrottledReportTelemetryEventsInBatch: ThrottledReportTelemetryEventsInBatch = (
  eventReportIntervalInMilliseconds
) => {
  if (!throttledReportTelemetryEventsInBatch) {
    throttledReportTelemetryEventsInBatch = throttle(
      reportTelemetryEventsInBatches,
      eventReportIntervalInMilliseconds,
      { leading: false }
    );
  }

  return throttledReportTelemetryEventsInBatch;
};

const dequeueAnalyticsEventsFromStore = async () => {
  const token = await getAuthToken();

  return token ? dequeueTelemetryEvents<AnalyticsEvent>('Analytics') : [];
};

type ReportAnalyticsEventsInBatches = () => Promise<void>;
const reportAnalyticsEventsInBatches: ReportAnalyticsEventsInBatches = async () => {
  const analyticsEvents = await dequeueAnalyticsEventsFromStore();
  if (analyticsEvents.length === 0) {
    return;
  }
  const analyticsEventInputs = await getReportAnalyticsEventsInput(analyticsEvents);
  const { batchSizeInBytes } = getTelemetryClientConfig();
  const eventBatches = batchReportAnalyticsEventInputs(analyticsEventInputs, batchSizeInBytes);

  await Promise.all(
    eventBatches.map((eventBatch) =>
      reportAnalyticsEventsNow({
        events: eventBatch,
      })
    )
  );
};

let throttledReportAnalyticsEventsInBatch: DebouncedFunc<ReportAnalyticsEventsInBatches>;

type ThrottledReportAnalyticsEventsInBatch = (
  eventReportIntervalInMilliseconds: number
) => DebouncedFunc<ReportAnalyticsEventsInBatches>;
export const getThrottledReportAnalyticsEventsInBatch: ThrottledReportAnalyticsEventsInBatch = (
  eventReportIntervalInMilliseconds
) => {
  if (!throttledReportAnalyticsEventsInBatch) {
    throttledReportAnalyticsEventsInBatch = throttle(
      reportAnalyticsEventsInBatches,
      eventReportIntervalInMilliseconds,
      {
        leading: false,
      }
    );
  }

  return throttledReportAnalyticsEventsInBatch;
};

const dequeueAnalyticsV2EventsFromStore = async () => {
  const token = await getAuthToken();

  return token ? dequeueTelemetryEvents<AnalyticsV2Event>('AnalyticsV2') : [];
};

type ReportAnalyticsV2EventsInBatches = () => Promise<void>;
const reportAnalyticsV2EventsInBatches: ReportAnalyticsV2EventsInBatches = async () => {
  const analyticsEvents = await dequeueAnalyticsV2EventsFromStore();
  if (analyticsEvents.length === 0) {
    return;
  }
  const analyticsEventInputs = analyticsEvents;
  const { batchSizeInBytes } = getTelemetryClientConfig();
  const eventBatches = batchReportAnalyticsEventInputs(analyticsEventInputs, batchSizeInBytes);

  await Promise.all(eventBatches.map((eventBatch) => reportAnalyticsV2EventsNow(eventBatch)));
};

let throttledReportAnalyticsV2EventsInBatch: DebouncedFunc<ReportAnalyticsEventsInBatches>;

type ThrottledReportAnalyticsV2EventsInBatch = (
  eventReportIntervalInMilliseconds: number
) => DebouncedFunc<ReportAnalyticsEventsInBatches>;
export const getThrottledReportAnalyticsV2EventsInBatch: ThrottledReportAnalyticsV2EventsInBatch = (
  eventReportIntervalInMilliseconds
) => {
  if (!throttledReportAnalyticsV2EventsInBatch) {
    throttledReportAnalyticsV2EventsInBatch = throttle(
      reportAnalyticsV2EventsInBatches,
      eventReportIntervalInMilliseconds,
      {
        leading: false,
      }
    );
  }

  return throttledReportAnalyticsV2EventsInBatch;
};

const dequeueLogEventsFromStore = async () => ({
  errorEvents: dequeueTelemetryEvents<ErrorEvent>('Error'),
  infoEvents: dequeueTelemetryEvents<InfoEvent>('Info'),
  performanceEvents: dequeueTelemetryEvents<PerformanceEvent>('Performance'),
});

const hasLogEvents = ({ errorEvents, infoEvents, performanceEvents }: LogEvents) =>
  [errorEvents.length, infoEvents.length, performanceEvents.length].some((count) => count > 0);

type ReportLogEventsInBatches = () => Promise<void>;
const reportLogEventsInBatches: ReportLogEventsInBatches = async () => {
  const logEvents = await dequeueLogEventsFromStore();
  if (!hasLogEvents(logEvents)) {
    return;
  }

  const reportLogEventsInput = await getReportLogEventsInput(logEvents);
  const { batchSizeInBytes } = getTelemetryClientConfig();
  const eventBatches = batchReportLogEventsInput(reportLogEventsInput, batchSizeInBytes);

  await Promise.all(eventBatches.map((eventBatch) => reportLogEventsNow(eventBatch)));
};

let throttledReportLogEventsInBatch: DebouncedFunc<ReportLogEventsInBatches>;

type ThrottledReportLogEventsInBatch = (
  eventReportIntervalInMilliseconds: number
) => DebouncedFunc<ReportLogEventsInBatches>;
export const getThrottledReportLogEventsInBatch: ThrottledReportLogEventsInBatch = (
  eventReportIntervalInMilliseconds
) => {
  if (!throttledReportLogEventsInBatch) {
    throttledReportLogEventsInBatch = throttle<ReportLogEventsInBatches>(
      reportLogEventsInBatches,
      eventReportIntervalInMilliseconds,
      {
        leading: false,
      }
    );
  }

  return throttledReportLogEventsInBatch;
};
