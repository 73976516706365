import { reportError, reportErrorNow } from '@ms/yammer-data/dist/telemetry';
import {
  AcquireTokenAuthError,
  AcquiredTokenExpiredError,
  AuthenticatorOptions,
  HandleTokenInUrlError,
  TokenExpirationParsingError,
  UnsupportedScopeError,
} from '@ms/yammer-web-authenticators';

export const reportAuthError: AuthenticatorOptions['onError'] = async (error, { authenticatorType, scopes }) => {
  const eventProperties = {
    authenticatorType,
    skipAuthentication: true,
    ...(scopes ? { scopes: scopes.join(',') } : null),
  };

  if (error instanceof AcquiredTokenExpiredError) {
    reportError({
      error,
      eventProperties: {
        ...eventProperties,
        expiration: error.expiration,
        endTime: error.endTimestamp,
        errorCode: 'AuthAcquiredTokenExpired',
        startTime: error.startTimestamp,
      },
    });
  }

  if (error instanceof AcquireTokenAuthError) {
    await reportErrorNow({
      error,
      eventProperties: {
        ...eventProperties,
        errorCode: 'AuthAcquireTokenSilentFailed',
      },
    });
  }

  if (error instanceof HandleTokenInUrlError) {
    reportError({
      error,
      eventProperties: {
        ...eventProperties,
        errorCode: 'AuthHandleTokenInUrlFailed',
      },
    });
  }

  if (error instanceof TokenExpirationParsingError) {
    reportError({
      error,
      eventProperties: {
        ...eventProperties,
        errorCode: 'AuthTokenExpirationParsingFailed',
      },
    });
  }

  if (error instanceof UnsupportedScopeError) {
    reportError({
      error,
      eventProperties: {
        ...eventProperties,
        errorCode: 'AuthUnsupportedScopeProvided',
      },
    });
  }
};
