import { error } from '@ms/yammer-console-logging';

const emailRegex = new RegExp(/([a-zA-Z0-9+_.-]+)@([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})/);
const ipRegex = new RegExp(/(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/);
const htmlRegex = new RegExp(/<((div)|(span)|(table)|(ul)|(body))>/);

const unsafeRegexObjects = [
  {
    regex: emailRegex,
    errorMessage: 'contains an email address',
  },
  {
    regex: ipRegex,
    errorMessage: 'contains an ip address',
  },
  {
    regex: htmlRegex,
    errorMessage: 'contains html',
  },
];

const testAgainstUnsafeRegexes = (value: string): string | undefined => {
  for (const regexObject of unsafeRegexObjects) {
    if (regexObject.regex.test(value)) {
      return regexObject.errorMessage;
    }
  }
};

export type TelemetryEventProperties = {
  [K in string]: never | string | boolean | number | undefined | string[] | readonly PerformanceServerTiming[];
};

/**
 * Checks the values of the custom telemetry properties passed and ensures that no email addresses, ip addresses,
 * or html content is being sent. Returns only the key-value pairs that meet these requirements. In the case of
 * non-compliant values, it logs to the console, indicating the name of the property key and event, and adds the
 * property key to a list of droppedKeys on the event
 */
type FilterUnsafeProperties = <T extends TelemetryEventProperties>(eventName: string, properties: T) => Partial<T>;

export const filterUnsafeProperties: FilterUnsafeProperties = <T extends TelemetryEventProperties>(
  eventName: string,
  properties: T
) => {
  const unsafeProperties: { readonly key: string; readonly errorMessage: string }[] = [];
  const safeProperties: Partial<T> = Object.keys(properties).reduce((obj, key) => {
    const value = properties[key];

    if (value != null) {
      const errorMessage = testAgainstUnsafeRegexes(value.toString());
      if (errorMessage) {
        unsafeProperties.push({ key, errorMessage });

        return obj;
      }
    }

    return {
      ...obj,
      [key]: properties[key],
    };
  }, {});

  // if any unsafeProperties were found, add a property on the event indicating their keys and send error to console
  if (unsafeProperties.length > 0) {
    const unsafeKeys = unsafeProperties.map((value) => value.key).join(', ');
    const errorMessages = unsafeProperties
      .map((value) => `The '${value.key}' property ${value.errorMessage}.`)
      .join(' ');
    error(
      `The '${unsafeKeys}' properties of event '${eventName}' have been removed from the event because euii / customer content has been detected. ${errorMessages}`
    );

    return {
      ...safeProperties,
      droppedKeys: unsafeKeys,
    };
  }

  return safeProperties;
};
