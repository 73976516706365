import { getItem, removeItem, setItem } from '@ms/yammer-session-storage';
import {
  BaseTelemetryEvent,
  ClientConfig,
  ClientContext,
  FirstMeaningfulPaintContext,
  HostingConfig,
  ScenarioCollection,
  TelemetryEventType,
} from '@ms/yammer-telemetry-support';
import { memoize } from 'lodash';

const yammerNamespace = 'Yammer';

const clientConfigKey = `${yammerNamespace}.ClientConfig`;
const clientContextKey = `${yammerNamespace}.ClientContext`;
const hostingConfigKey = `${yammerNamespace}.HostingConfig`;
const userContextKey = `${yammerNamespace}.UserContext`;
const skipCleanupKey = `${yammerNamespace}.SkipCleanup`;
const scenarioPerformanceKey = `${yammerNamespace}.ScenarioPerformance`;
const firstMeaningfulPaintContextKey = `${yammerNamespace}.FirstMeaningfulPaintContext`;

const getEventQueueKey = (eventType: string) => `${yammerNamespace}.${eventType}`;

const getTelemetryEventQueue = <T extends BaseTelemetryEvent>(eventType: TelemetryEventType) => {
  const eventQueueKey = getEventQueueKey(eventType);

  return getItem<T[]>(eventQueueKey) || [];
};

const setTelemetryEventQueue = <T extends BaseTelemetryEvent>(eventType: TelemetryEventType, events: T[]) => {
  const eventQueueKey = getEventQueueKey(eventType);
  setItem<T[]>(eventQueueKey, events);
};

type EnqueueTelemetryEvents = <T extends BaseTelemetryEvent>(eventType: TelemetryEventType, events: T[]) => void;
export const enqueueTelemetryEvents: EnqueueTelemetryEvents = <T extends BaseTelemetryEvent>(
  eventType: TelemetryEventType,
  events: T[]
) => {
  const eventsQueue = getTelemetryEventQueue<T>(eventType);
  setTelemetryEventQueue(eventType, [...eventsQueue, ...events]);
};

type ClearAllTelemetryEvents = () => void;
export const clearAllTelemetryEvents: ClearAllTelemetryEvents = () => {
  setTelemetryEventQueue('AnalyticsV2', []);
  setTelemetryEventQueue('Analytics', []);
  setTelemetryEventQueue('Performance', []);
  setTelemetryEventQueue('Error', []);
  setTelemetryEventQueue('Info', []);
};

type DequeueTelemetryEvents = <T extends BaseTelemetryEvent>(eventType: TelemetryEventType) => T[];
export const dequeueTelemetryEvents: DequeueTelemetryEvents = <T extends BaseTelemetryEvent>(
  eventType: TelemetryEventType
) => {
  const eventsQueue = getTelemetryEventQueue<T>(eventType);
  setTelemetryEventQueue(eventType, []);

  return eventsQueue;
};

type SetTelemetryConfig = (clientConfig: ClientConfig, hostingConfig: HostingConfig) => void;
export const setTelemetryConfig: SetTelemetryConfig = (clientConfig: ClientConfig, hostingConfig: HostingConfig) => {
  setItem<ClientConfig>(clientConfigKey, clientConfig);
  setItem<HostingConfig>(hostingConfigKey, hostingConfig);
};

const getTelemetryConfig = <T>(configKey: string) => {
  const config = getItem<T>(configKey);

  if (!config) {
    throw new Error('Telemetry is not configured!');
  }

  return config;
};

export const isTelemetryClientConfigConfigured = () => getItem<ClientConfig>(clientConfigKey) != null;

type SetSkipCleanupFlag = (skipCleanupFlag: boolean) => void;
export const setSkipCleanupFlag: SetSkipCleanupFlag = (skipCleanupFlag) => {
  setItem<boolean>(skipCleanupKey, skipCleanupFlag);
};

type GetSkipCleanupFlag = () => boolean | null;
export const getSkipCleanupFlag: GetSkipCleanupFlag = () => getItem<boolean>(skipCleanupKey);

type GetTelemetryClientConfig = () => ClientConfig;
export const getTelemetryClientConfig: GetTelemetryClientConfig = () =>
  getTelemetryConfig<ClientConfig>(clientConfigKey);

type GetTelemetryHostingConfig = () => HostingConfig;
export const getTelemetryHostingConfig: GetTelemetryHostingConfig = () =>
  getTelemetryConfig<HostingConfig>(hostingConfigKey);

export interface UserContext {
  readonly userId: string;
  readonly networkId: string;
  readonly officeUserId?: string;
}

type RemoveUserContext = () => void;
export const removeUserContext: RemoveUserContext = () => {
  removeItem(userContextKey);
};

type SetUserContext = (userContext: UserContext) => void;
export const setUserContext: SetUserContext = (userContext: UserContext) => {
  setItem<UserContext>(userContextKey, userContext);
};

type GetUserContext = () => UserContext | null;
export const getUserContext: GetUserContext = () => getItem<UserContext>(userContextKey);

type RemoveClientContext = () => void;
export const removeClientContext: RemoveClientContext = () => {
  removeItem(clientContextKey);
};

type SetClientContext = (clientContext: ClientContext) => void;
export const setClientContext: SetClientContext = (clientContext: ClientContext) => {
  setItem<ClientContext>(clientContextKey, clientContext);
};

type GetClientContext = () => ClientContext;
export const getClientContext: GetClientContext = () => getItem<ClientContext>(clientContextKey) || {};

type UpdateClientContext = (clientContext: Partial<ClientContext>) => void;
export const updateClientContext: UpdateClientContext = (clientContext: Partial<ClientContext>) => {
  const currentClientContext = getClientContext();
  setClientContext({ ...currentClientContext, ...clientContext });
};

type RemoveScenarioPerformanceCollection = () => void;
export const removeScenarioPerformanceCollection: RemoveScenarioPerformanceCollection = () => {
  removeItem(scenarioPerformanceKey);
};

type GetScenarioPerformanceCollection = () => ScenarioCollection;
export const getScenarioPerformanceCollection: GetScenarioPerformanceCollection = () =>
  getItem<ScenarioCollection>(scenarioPerformanceKey) || {};

type SetScenarioPerformanceCollection = (scenarioCollection: ScenarioCollection) => void;
export const setScenarioPerformanceCollection: SetScenarioPerformanceCollection = (
  scenarioCollection: ScenarioCollection
) => {
  setItem<ScenarioCollection>(scenarioPerformanceKey, scenarioCollection);
};

// eslint-disable-next-line no-restricted-globals
const decodeId = memoize((base64Id) => JSON.parse(atob(base64Id)).id);

type GetDecodedId = () => string | undefined;

export const getDecodedUserId: GetDecodedId = () => {
  const userContext = getUserContext();

  return userContext ? decodeId(userContext.userId) : undefined;
};

export const getDecodedNetworkId: GetDecodedId = () => {
  const userContext = getUserContext();

  return userContext ? decodeId(userContext.networkId) : undefined;
};

export const getOfficeUserId = (): string | undefined => {
  const userContext = getUserContext();

  return userContext?.officeUserId;
};

const defaultFirstMeaningfulPaintContext: FirstMeaningfulPaintContext = {
  firstMeaningfulPaintReported: false,
  firstPageLoadReported: false,
  firstPageLoadAbandoned: false,
};
type GetFirstMeaningfulPaintContext = () => FirstMeaningfulPaintContext;
export const getFirstMeaningfulPaintContext: GetFirstMeaningfulPaintContext = () =>
  getItem<FirstMeaningfulPaintContext>(firstMeaningfulPaintContextKey) || defaultFirstMeaningfulPaintContext;

type SetFirstMeaningfulPaintContext = (firstMeaningfulPaintContext: FirstMeaningfulPaintContext) => void;
export const setFirstMeaningfulPaintContext: SetFirstMeaningfulPaintContext = (
  firstMeaningfulPaintContext: FirstMeaningfulPaintContext
) => {
  setItem<FirstMeaningfulPaintContext>(firstMeaningfulPaintContextKey, firstMeaningfulPaintContext);
};

type RemoveFirstMeaningfulPaintContext = () => void;
export const removeFirstMeaningfulPaintContext: RemoveFirstMeaningfulPaintContext = () => {
  removeItem(firstMeaningfulPaintContextKey);
};
