export enum NetworkType {
  Canonical = 'Canonical',
  External = 'External',
}

export interface NetworkContext {
  readonly networkType: NetworkType;
  readonly permalink: string;
  readonly networkPath: string;
}

export type CurrentNetworkContext = NetworkContext | null;
