/* istanbul ignore file */

type GetPolyfills = () => Promise<any>[];
export const getPolyfills: GetPolyfills = () => {
  const polyfills: Promise<any>[] = [];

  if (!('requestIdleCallback' in window) || !('cancelIdleCallback' in window)) {
    polyfills.push(
      import(/* webpackChunkName: "2-polyfill-idle" */ 'requestidlecallback').then((module) => {
        const { request: requestIdleCallback, cancel: cancelIdleCallback } = module.default;
        window.requestIdleCallback = requestIdleCallback;
        window.cancelIdleCallback = cancelIdleCallback;

        return module;
      })
    );
  }

  return polyfills;
};
