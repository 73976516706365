import { error as logErrorToConsole } from '@ms/yammer-console-logging';
import { BaseTelemetryEvent, LogEvent, LogEvents, TelemetryEventType } from '@ms/yammer-telemetry';
import { enqueueTelemetryEvents, getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { getThrottledReportLogEventsInBatch } from './reportInBatches';
import { getReportLogEventsInput } from './reportLogEventsInput';
import { reportLogEventsNow } from './reportTelemetryEventsToServer';

const eventsAllowedForReportNow = ['AcquireTokenAuthError'];

type EnqueueAndThrottleReportLogEvents = <T extends BaseTelemetryEvent>(
  eventType: TelemetryEventType,
  events: T[]
) => void;
export const enqueueAndThrottleReportLogEvents: EnqueueAndThrottleReportLogEvents = <T extends BaseTelemetryEvent>(
  eventType: TelemetryEventType,
  events: T[]
) => {
  enqueueTelemetryEvents<T>(eventType, events);
  const throttledReportAction = getThrottledReportLogEventsInBatch(
    getTelemetryClientConfig().eventReportIntervalInMilliseconds
  );

  throttledReportAction();
};

type ReportLogEvent = (event: LogEvent) => void;
export const reportLogEvent: ReportLogEvent = (event) => {
  enqueueAndThrottleReportLogEvents(event.type, [event]);
};

type ReportLogEventNow = (event: LogEvent) => Promise<void>;

export const reportLogEventNow: ReportLogEventNow = async (event) => {
  const logEvents: LogEvents = {
    errorEvents: event.type === 'Error' ? [event] : [],
    infoEvents: event.type === 'Info' ? [event] : [],
    performanceEvents: event.type === 'Performance' ? [event] : [],
  };
  if (!eventsAllowedForReportNow.includes(event.name)) {
    logErrorToConsole('Event not allowed for report now');

    return;
  }

  const reportLogEventInput = await getReportLogEventsInput(logEvents);
  await reportLogEventsNow(reportLogEventInput);
};
