import { HostingConfig } from '@ms/yammer-telemetry-support';

import { OverrideHostingConfig, overrideHostingEnvironment, overrideHostname } from './overrideHostingConfig';
import { readHostingConfigFromMetatags } from './readHostingConfigFromMetatags';

const applyOverrides = (hostingConfig: HostingConfig) => {
  const overrides: OverrideHostingConfig[] = [overrideHostingEnvironment, overrideHostname];

  return overrides.reduce((config, override) => override(config), hostingConfig);
};

export type ReadHostingConfiguration = () => HostingConfig;

export const readHostingConfiguration: ReadHostingConfiguration = () => {
  const hostingConfig = readHostingConfigFromMetatags();

  return applyOverrides(hostingConfig);
};
