interface AcquiredTokenExpiredErrorOptions {
  readonly endTimestamp: number;
  readonly expiration: number;
  readonly startTimestamp: number;
}

export class AcquiredTokenExpiredError extends Error {
  public endTimestamp: number;
  public expiration: number;
  public name: string;
  public startTimestamp: number;

  constructor({ expiration, startTimestamp, endTimestamp }: AcquiredTokenExpiredErrorOptions) {
    super(`token has expiration ${expiration}`);

    this.endTimestamp = endTimestamp;
    this.expiration = expiration;
    this.name = 'AcquiredTokenExpiredError';
    this.startTimestamp = startTimestamp;

    Object.setPrototypeOf(this, AcquiredTokenExpiredError.prototype);
  }
}
