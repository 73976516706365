/* eslint-disable max-classes-per-file */
import { RequestInitiator } from '@ms/yammer-telemetry-support';

import { ApiRequest, GraphqlError, GraphqlErrorReason, GraphqlOperation } from './types';

export class ApiError extends Error {
  public requestType: string;
  public apiName?: string;
  public status?: number;
  public initiator?: RequestInitiator;
  public requestId?: string | null;
  public afdRef?: string | null;
  public traceId?: string | null;
  public wwwAuthenticate?: string | null;

  constructor(request: ApiRequest, error: Error | string, status?: number, responseHeaders?: Headers) {
    super();

    this.name = 'ApiError';
    this.apiName = request.apiName;
    this.message = error instanceof Error ? error.message : error;
    this.requestType = request.requestType;
    this.stack = new Error().stack;
    this.status = status;
    this.initiator = request.initiator;
    this.requestId = responseHeaders?.get('X-Request-Id') || responseHeaders?.get('request-id');
    this.afdRef = responseHeaders?.get('X-MSEdge-Ref');
    this.traceId = responseHeaders?.get('x-trace-id');
    this.wwwAuthenticate = responseHeaders?.get('www-authenticate');

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export class SharePointApiError extends ApiError {
  public spRequestGuid?: string | null;

  constructor(request: ApiRequest, error: Error | string, status?: number, responseHeaders?: Headers) {
    super(request, error, status, responseHeaders);

    this.name = 'SharePointApiError';
    this.spRequestGuid = responseHeaders?.get('sprequestguid');

    Object.setPrototypeOf(this, SharePointApiError.prototype);
  }
}

const gqlErrorCodeOverrides: Record<string, GraphqlErrorReason> = {
  CANNOT_REMOVE_LAST_ADMIN: 'FINAL_ADMIN_WARNING',
  INVALID_FILENAME: 'UPLOAD_INVALID_FILENAME',
  ONEDRIVE_FORBIDDEN: 'UPLOAD_NO_GROUP_ACCESS',
  ONEDRIVE_UNAUTHORIZED: 'UPLOAD_NO_GROUP_ACCESS',
  VIDEO_UPLOAD_MAX_DURATION_EXCEEDED: 'VIDEO_UPLOAD_MAX_DURATION_EXCEEDED',
  MEDIA_FILE_FORMAT_NOT_SUPPORTED: 'MEDIA_FILE_FORMAT_NOT_SUPPORTED',
};

export class GraphqlApiError extends Error {
  public apiName: string;
  public correlationId?: string;
  public isPartialError: boolean;
  public operationName: string;
  public reason: GraphqlErrorReason;
  public requestId: string;
  public requestType: string;
  public status?: number;
  public initiator?: RequestInitiator;
  public afdRef?: string | null;
  public traceId?: string | null;

  public innerError?: Error;

  constructor(request: ApiRequest, error: GraphqlError | Error | string, status?: number, responseHeaders?: Headers) {
    super();

    this.name = 'GraphqlApiError';
    this.apiName = this.operationName = (request.body as GraphqlOperation).operationName;
    this.reason = 'NONE';
    this.requestId = request.headers ? request.headers['X-Request-Id'] ?? '' : '';
    this.requestType = request.requestType;
    this.stack = new Error().stack;
    this.status = status;
    this.isPartialError = false;
    this.initiator = request.initiator;
    this.afdRef = responseHeaders?.get('X-MSEdge-Ref');
    this.traceId = responseHeaders?.get('x-trace-id');

    if (typeof error === 'string') {
      this.message = error;
    } else if (error instanceof Error) {
      this.message = error.message;
      this.innerError = error;
    } else {
      this.message = error.message ?? 'Unknown GraphqlError.';
      this.correlationId = error.correlationId ?? '<missing>';

      const code = error?.extensions?.code ?? 'UNKNOWN';
      this.reason = gqlErrorCodeOverrides[code] ?? code;
      this.isPartialError = !!(error.path && error.path.length > 1);
    }

    Object.setPrototypeOf(this, GraphqlApiError.prototype);
  }
}

export class MaybeCorsError extends Error {
  private innerError?: Error;

  constructor(innerError?: Error) {
    super('BlockedByCors');
    this.innerError = innerError;
    Object.setPrototypeOf(this, MaybeCorsError.prototype);
  }

  public getInnerError(): Error | undefined {
    return this.innerError;
  }
}

type IsGraphqlApiError = (error: unknown) => error is GraphqlApiError;
export const isGraphqlApiError: IsGraphqlApiError = (error): error is GraphqlApiError =>
  error ? error instanceof GraphqlApiError : false;

type GetGraphqlApiErrorMessage = (error: unknown) => string | undefined;
export const getGraphqlApiErrorMessage: GetGraphqlApiErrorMessage = (error) =>
  isGraphqlApiError(error) ? error.message : undefined;

type GetGraphqlApiErrorReason = (error: unknown) => GraphqlErrorReason;
export const getGraphqlApiErrorReason: GetGraphqlApiErrorReason = (error) =>
  isGraphqlApiError(error) ? error.reason : 'NONE';

type IsApiError = (error: unknown) => error is ApiError;
export const isApiError: IsApiError = (error): error is ApiError => (error ? error instanceof ApiError : false);

type IsCorsError = (error: unknown) => error is MaybeCorsError;
export const isCorsError: IsCorsError = (error): error is MaybeCorsError =>
  error ? error instanceof MaybeCorsError : false;

export const maybeCorsError: (error: unknown) => boolean = (error) => {
  if (!error) {
    return false;
  }

  if (error instanceof TypeError) {
    return true;
  }

  return isGraphqlApiError(error) && maybeCorsError(error.innerError);
};

const hasField = <T>(obj: unknown, field: string): obj is T => {
  if (obj && typeof obj === 'object' && field in obj) {
    return true;
  }

  return false;
};

type GetGraphqlApiErrorCode = (payload: unknown) => GraphqlErrorReason;
export const getGraphqlApiErrorCode: GetGraphqlApiErrorCode = (payload: unknown) => {
  if (hasField<{ readonly errorCode: GraphqlErrorReason }>(payload, 'errorCode')) {
    return payload.errorCode;
  }

  if (hasField<{ readonly error: unknown }>(payload, 'error')) {
    return getGraphqlApiErrorReason(payload.error);
  }

  return 'NONE';
};
