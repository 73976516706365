import { CookieSerializeOptions, parse, serialize } from 'cookie';

const defaultOptions: CookieSerializeOptions = {
  secure: true,
};

export class ThirdPartyCookieError extends Error {
  public cookieName: string;

  constructor(cookieName: string) {
    super();

    this.name = 'ThirdPartyCookieError';
    this.cookieName = cookieName;
    this.message = `Unable to set cookie: ${cookieName}`;
    this.stack = new Error().stack;

    Object.setPrototypeOf(this, ThirdPartyCookieError.prototype);
  }
}

export type CookieOptions = Pick<
  CookieSerializeOptions,
  'domain' | 'expires' | 'path' | 'httpOnly' | 'secure' | 'encode' | 'sameSite' | 'partitioned'
>;

export type RemoveCookieOptions = Pick<CookieOptions, Exclude<keyof CookieOptions, 'expires'>>;

type SetCookie = (name: string, value: string, options?: CookieOptions) => void;
export const set: SetCookie = (name, value, options) => {
  // The option.partitioned is used to determine whether the cookie should be set as partitioned or unpartitioned,
  // based on the feature flight. This is a temporary override of the default partition option.
  const setUnpartitionedCookieSucceeded = setCookie(name, value, { ...options, partitioned: false });
  if (setUnpartitionedCookieSucceeded) {
    return;
  }

  // istanbul ignore next
  if (options?.partitioned) {
    const setPartitionedCookieSucceeded = setCookie(name, value, { ...options, secure: true, partitioned: true });
    if (setPartitionedCookieSucceeded) {
      return;
    }
  }

  throw new ThirdPartyCookieError(name);
};

const setCookie = (name: string, value: string, options?: CookieOptions) => {
  const cookie = serialize(name, value, {
    ...defaultOptions,
    ...options,
  });

  document.cookie = cookie;

  return get(name) === value;
};

type RemoveCookie = (name: string, options?: RemoveCookieOptions) => void;
export const remove: RemoveCookie = (name, options) => {
  removeCookie(name, options);
  if (get(name)) {
    removeCookie(name, { ...options, secure: true, partitioned: true });
  }
};

const removeCookie: RemoveCookie = (name, options) => {
  const cookie = serialize(name, '', {
    ...defaultOptions,
    ...options,
    maxAge: 0,
    expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
  });

  document.cookie = cookie;
};

type GetCookie = (name: string) => string | undefined;
export const get: GetCookie = (name) => {
  const parsed = parse(document.cookie);

  return parsed[name];
};
