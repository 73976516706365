import { reportError, reportInfo } from '@ms/yammer-data/dist/telemetry';
import { WriteHostingCookieStatus } from '@ms/yammer-telemetry';
import { isSameSiteNoneCompatible } from 'should-send-same-site-none';

import { CookieOptions } from '../cookies';

export type GetCookieName = () => string;
export type ShouldWriteCookie = (cookieValue: string) => boolean;
export type WriteCookie = (value: string, options: CookieOptions) => void;

export interface WriteHostingCookieOptions {
  readonly cookieName: string;
  readonly cookieValue: string;
  readonly shouldWriteCookie: ShouldWriteCookie;
  readonly writeCookie: WriteCookie;
  readonly shouldSetSameSite: boolean;
  readonly partitioned?: boolean;
  readonly expires?: Date;
  readonly path?: string;
}

const defaultCookieOptions: CookieOptions = {
  secure: true,
  path: '/',
};

type GetCookieOptions = (
  shouldSetSameSite: boolean,
  expires?: Date,
  path?: string,
  partitioned?: boolean
) => CookieOptions;
const getCookieOptions: GetCookieOptions = (shouldSetSameSite, expires, path = '/', partitioned = false) => {
  const sameSite = shouldSetSameSite && isSameSiteNoneCompatible(navigator.userAgent) ? 'none' : undefined;

  return { ...defaultCookieOptions, sameSite, expires, path, partitioned };
};

const reportHostingCookieSet = (name: string, statusMessage: WriteHostingCookieStatus, contextMessage?: string) => {
  reportInfo({
    eventName: 'hosting_cookie_set',
    eventProperties: { name, statusMessage, contextMessage },
  });
};

const tryCheckIfShouldWriteCookie = (shouldWriteCookie: ShouldWriteCookie, cookieValue: string) => {
  try {
    return shouldWriteCookie(cookieValue);
  } catch (error) {
    reportError({ error, eventProperties: { errorCode: 'ShouldWriteCookieCheckFailed' } });

    return true;
  }
};

export type WriteHostingCookie = (input: WriteHostingCookieOptions) => void;
export const writeHostingCookie: WriteHostingCookie = ({
  cookieName,
  cookieValue,
  shouldWriteCookie,
  writeCookie,
  shouldSetSameSite,
  expires,
  partitioned,
  path,
}) => {
  try {
    if (!tryCheckIfShouldWriteCookie(shouldWriteCookie, cookieValue)) {
      reportHostingCookieSet(cookieName, 'skipped');

      return;
    }

    const cookieOptions = getCookieOptions(shouldSetSameSite, expires, path, partitioned);

    writeCookie(cookieValue, cookieOptions);
    reportHostingCookieSet(cookieName, 'succeeded');
  } catch (error) {
    reportHostingCookieSet(cookieName, 'failed', error.message);
  }
};
