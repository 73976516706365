import { AnalyticsEvent } from '@ms/yammer-telemetry';

import { AnalyticsEventInput } from './eventInputTypes';
import { filterNullishProperties } from './filterUndefinedEventPropertiesInput';
import { getApplicationContextInput } from './getApplicationContextInput';
import { getAnalyticsEventInput } from './getTelemetryEventInput';

type GetAnalyticsEventInputWithContext = (event: AnalyticsEvent) => Promise<AnalyticsEventInput>;
const getAnalyticsEventInputWithContext: GetAnalyticsEventInputWithContext = async (event) => {
  const analyticsEventInput = await getAnalyticsEventInput(event);

  return {
    name: analyticsEventInput.name,
    properties: filterNullishProperties({ ...analyticsEventInput.properties, ...getApplicationContextInput() }),
  };
};

type GetReportAnalyticsEventsInput = (analyticsEvents: AnalyticsEvent[]) => Promise<AnalyticsEventInput[]>;
export const getReportAnalyticsEventsInput: GetReportAnalyticsEventsInput = (analyticsEvents) =>
  Promise.all(analyticsEvents.map((event) => getAnalyticsEventInputWithContext(event)));
