import { GetEventsForPerformanceEntries } from '../types';

import { getEventTimingPerformanceEvent } from './getEventTimingPerformanceEvent';
import { PerformanceEventTiming } from './types';

const reportableEventTimings = [
  'beforeinput',
  'input',
  'keydown',
  'keypress',
  'keyup',
  'mousedown',
  'mouseenter',
  'mouseleave',
  'mouseout',
  'mouseover',
  'mouseup',
];

const isReportableEventTimingEntry = (entry: PerformanceEventTiming): boolean =>
  reportableEventTimings.includes(entry.name) || entry.entryType === 'first-input';

export const getEventsForEventTimingEntries: GetEventsForPerformanceEntries = (entries) =>
  entries
    .filter((entry) => isReportableEventTimingEntry(entry as PerformanceEventTiming))
    .map((entry) => getEventTimingPerformanceEvent(entry as PerformanceEventTiming));
