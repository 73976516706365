import { getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { acquireTokenWithoutTelemetry } from '../../authentication';

type GetAuthToken = () => Promise<string | null>;
export const getAuthToken: GetAuthToken = async () => {
  const { resource } = getTelemetryClientConfig();
  try {
    return await acquireTokenWithoutTelemetry({ scopes: [resource] });
  } catch {
    return null;
  }
};
