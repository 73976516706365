import { error } from '@ms/yammer-console-logging';

type GetItem = <T>(key: string) => T | null;
export const getItem: GetItem = (key: string) => {
  const item = sessionStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

type SetItem = <T>(key: string, item: T) => void;
export const setItem: SetItem = <T>(key: string, item: T) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    error(e);
  }
};

type RemoveItem = (key: string) => void;
export const removeItem: RemoveItem = (key: string) => {
  sessionStorage.removeItem(key);
};
